import {
  Card,
  Col,
  Row,
  Space,
  Typography,
  Checkbox,
  Divider,
  Select,
} from 'antd';
import React, { useMemo } from 'react';
import { LineChartOutlined, BarChartOutlined } from '@ant-design/icons';
import ChartWidget from '../chart-widget';
import { COLORS } from '../../constants/ChartConstant';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { ReportContext } from '../../context/report';
const { Text, Title } = Typography;

const ReportChart = ({ reportData = {}, chart_data = '' }) => {
  const { by_time, options, handleByTime, chart_type, setChartType } =
    useContext(ReportContext);
  const { t } = useTranslation();
  const categories = useMemo(
    () => reportData?.chart?.map((item) => item.time),
    [reportData?.chart]
  );
  const chartData = useMemo(() => {
    return [
      {
        name: t('orders'),
        data: reportData?.chart?.map((item) => item[chart_data]),
      },
    ];
  }, [reportData?.chart]);
  return (
    <Row gutter={24}>
      <Col span={24}>
        <Card>
          <Row gutter={24}>
            <Col span={22}>
              <Row gutter={24}>
                <Col span={17}>
                  <Space size='large' className='d-flex'>
                    <Title level={3} className='mb-0'>
                      Items Sold
                    </Title>
                    {/* <Checkbox className='d-flex'>
                      Last month (Nov 1-30, 2022)
                    </Checkbox>
                    <Text>39</Text> */}
                  </Space>
                </Col>
                {/* <Col span={8} className='d-flex justify-content-between'>
                  <Checkbox>Previous period (Nov 1-30, 2022)</Checkbox>
                  <Text className='flex-grow-0'>39</Text>
                </Col> */}
                <Col span={7} className='d-flex justify-content-end'>
                  <Select
                    style={{ width: 100 }}
                    onChange={handleByTime}
                    options={options}
                    defaultValue={by_time}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={2}>
              <Divider type='vertical' style={{ height: '100%' }} />
              <Space>
                <LineChartOutlined
                  style={{
                    fontSize: '22px',
                    cursor: 'pointer',
                    color: chart_type === 'line' ? 'green' : '',
                  }}
                  onClick={() => setChartType('line')}
                />
                <BarChartOutlined
                  style={{
                    fontSize: '22px',
                    cursor: 'pointer',
                    color: chart_type === 'bar' ? 'green' : '',
                  }}
                  onClick={() => setChartType('bar')}
                />
              </Space>
            </Col>
          </Row>
          <Divider />
          <ChartWidget
            type={chart_type}
            card={false}
            series={chartData}
            xAxis={categories}
            height={280}
            customOptions={{
              colors: [COLORS[6], COLORS[0]],
              legend: {
                show: false,
              },
              stroke: {
                width: 2.5,
                curve: 'smooth',
              },
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default ReportChart;
