import request from './request';

const ReportService = {
  getChart: (params) =>
    request.get('dashboard/admin/products/report/chart', { params }),
  getProducts: (params) =>
    request.get('dashboard/admin/products/report/paginate', { params }),
  getOrderChart: (params) =>
    request.get('dashboard/admin/orders/report/chart', { params }),
  getOrderProducts: (params) =>
    request.get('dashboard/admin/orders/report/paginate', { params }),
  getStocks: (params) =>
    request.get('dashboard/admin/stocks/report/paginate', { params }),
};

export default ReportService;
