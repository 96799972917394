import {
  Card,
  Col,
  Row,
  Space,
  Typography,
  Table,
  Tag,
  Button,
  Checkbox,
  Dropdown,
  Menu,
  Divider,
  DatePicker,
} from 'antd';
import React from 'react';
import SearchInput from '../../components/search-input';
import {
  CloudDownloadOutlined,
  MoreOutlined,
  DownOutlined,
  LineChartOutlined,
  BarChartOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import ReportService from '../../services/reports';
import { disableRefetch } from '../../redux/slices/menu';
import FilterColumns from '../../components/filter-column';
const { Title } = Typography;
const ReportStock = () => {
  const dispatch = useDispatch();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const [loading, setLoading] = useState(false);
  const [reportProducts, setReportProduct] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [columns, setColumns] = useState([
    {
      title: 'Product title',
      dataIndex: 'name',
      key: 'name',
      render: (_, data) => {
        return <>{data?.countable?.translation?.title}</>;
      },
      is_show: true,
    },
    {
      title: 'Bar code',
      dataIndex: 'countable.bar_code',
      key: 'countable.bar_code',
      is_show: true,
      render: (_, data) => {
        return <>{data?.stock?.countable?.bar_code || '-'}</>;
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, data) => {
        const status = Boolean(data?.countable?.active);
        return (
          <Tag color={status ? 'green' : 'red'} key={data.id}>
            {status ? 'In stock' : 'Out of stock'}
          </Tag>
        );
      },
      is_show: true,
    },
    {
      title: 'Stock',
      key: 'stock',
      dataIndex: 'quantity',
      is_show: true,
    },
  ]);
  const fetchProduct = (page, perPage) => {
    setLoading(true);
    ReportService.getStocks({
      page,
      perPage,
    })
      .then((res) => {
        setReportProduct(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch(disableRefetch(activeMenu));
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchProduct();
  }, [activeMenu.refetch]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onChangePagination = (pagination) => {
    const { pageSize: perPage, current: page } = pagination;
    fetchProduct(page, perPage);
  };
  const excelExport = () => {
    setDownloading(true);
    ReportService.getStocks({ export: 'excel' })
      .then((res) => {
        const body = res.data.link;
        window.location.href = body;
      })
      .finally(() => setDownloading(false));
  };

  return (
    <>
      <Row gutter={24} className='mb-4'>
        <Col span={6}>
          {/* <Dropdown overlay={menu}>
            <Button style={{ width: '100%' }} icon={<FilterOutlined />}>
              Filter by date range
            </Button>
          </Dropdown> */}
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Card>
            <Row gutter={24} className='align-items-center mb-2'>
              <Col span={21}>
                <Title level={2} className='mb-0'>
                  Stock
                </Title>
              </Col>
              <Col span={3}>
                <Space>
                  <Button
                    icon={<CloudDownloadOutlined />}
                    loading={downloading}
                    onClick={excelExport}
                  >
                    Download
                  </Button>
                  <FilterColumns columns={columns} setColumns={setColumns} />
                </Space>
              </Col>
            </Row>
            <Table
              rowSelection={rowSelection}
              columns={columns?.filter((item) => item.is_show)}
              dataSource={reportProducts.data || []}
              rowKey={(row) => row.id}
              loading={loading}
              pagination={{
                pageSize: reportProducts?.per_page,
                page: reportProducts?.current_page || 1,
                total: reportProducts?.total,
                defaultCurrent: 1,
              }}
              onChange={onChangePagination}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ReportStock;
