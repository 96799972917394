export const PROJECT_NAME = 'Goshops';
export const BASE_URL = 'https://mydemoshop.mactechhub.com';
export const WEBSITE_URL = 'https://goshops.org';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL = BASE_URL + '/storage/images/';
export const MAP_API_KEY = 'AIzaSyAIZAHqq0Gpw0yNcq6LgsQd9EAGpee5sMg';
export const export_url = BASE_URL + '/storage/';
export const VAPID_KEY = 'fdduoElultHsIt88NXFsyeE_olSEkZGJN_-jd_Omba8';

export const API_KEY = 'AIzaSyAjfLE0oI4C_52rO4JShLgwO2CbZyuoFaY';
export const AUTH_DOMAIN = 'routines-7bbc0.firebaseapp.com';
export const PROJECT_ID = 'routines-7bbc0';
export const STORAGE_BUCKET = 'routines-7bbc0.appspot.com';
export const MESSAGING_SENDER_ID = '186801247701';
export const APP_ID = '1:186801247701:web:6b245a1faa8234e6abd95c';
export const MEASUREMENT_ID = 'G-TBD8YVH0CV';
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];
