import {
  Card,
  Col,
  Row,
  Space,
  Typography,
  Table,
  Tag,
  Button,
  Checkbox,
  Dropdown,
  Menu,
  Divider,
  DatePicker,
  Spin,
} from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import SearchInput from '../../components/search-input';
import {
  CloudDownloadOutlined,
  MoreOutlined,
  DownOutlined,
  LineChartOutlined,
  BarChartOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import ReportService from '../../services/reports';
import { disableRefetch } from '../../redux/slices/menu';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ReportChart from '../../components/report/chart';
import moment from 'moment';
import { ReportContext } from '../../context/report';
import FilterColumns from '../../components/filter-column';
import useDidUpdate from '../../helpers/useDidUpdate';
const { Text, Title } = Typography;
const { RangePicker } = DatePicker;
const ReportProducts = () => {
  const dispatch = useDispatch();
  const { date_from, date_to, by_time, chart, handleChart, handleDateRange } =
    useContext(ReportContext);
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState({});
  const [reportProducts, setReportProduct] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [search, setSearch] = useState('');

  const [columns, setColumns] = useState([
    {
      title: 'Product title',
      dataIndex: 'name',
      key: 'name',
      render: (_, data) => {
        return <>{data?.stock?.countable?.translation?.title}</>;
      },
      is_show: true,
    },
    {
      title: 'Bar code',
      dataIndex: 'items_sold',
      key: 'items_sold',
      is_show: true,
      render: (_, data) => {
        return <>{data?.stock?.countable?.bar_code || '-'}</>;
      },
    },
    {
      title: 'Item sold',
      dataIndex: 'items_sold',
      key: 'items_sold',
      sorter: (a, b) => a.items_sold - b.items_sold,
      is_show: true,
    },
    {
      title: 'Net sales',
      dataIndex: 'total_price',
      key: 'total_price',
      sorter: (a, b) => a.total_price - b.total_price,
      is_show: true,
    },
    {
      title: 'Orders',
      key: 'order_count',
      dataIndex: 'order_count',
      is_show: true,
    },
    {
      title: 'Category',
      key: 'category',
      dataIndex: 'category',
      render: (_, data) => {
        return <>{data?.stock?.countable?.category?.translation?.title}</>;
      },
      is_show: true,
    },
    {
      title: 'Variations',
      key: 'variations',
      dataIndex: 'variations',
      is_show: true,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, data) => {
        const status = Boolean(data?.stock?.countable?.active);
        return (
          <Tag color={status ? 'green' : 'red'} key={data.id}>
            {status ? 'Active' : 'Inactive'}
          </Tag>
        );
      },
      is_show: true,
    },
    {
      title: 'Stock',
      key: 'stock',
      dataIndex: 'stock.quantity',
      render: (_, data) => {
        return <>{data.stock.quantity}</>;
      },
      is_show: true,
    },
  ]);
  const chart_type = [
    { value: 'items_sold', label: 'Item sold', qty: 'itemsSoldSum' },
    { value: 'net_sales', label: 'Net Sales', qty: 'netSalesSum' },
    { value: 'orders', label: 'Orders', qty: 'ordersCount' },
  ];
  const fetchReport = () => {
    setLoading(true);
    ReportService.getChart({ date_from, date_to, by_time, chart })
      .then((res) => {
        setReportData(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch(disableRefetch(activeMenu));
        setLoading(false);
      });
  };
  const fetchProduct = (page, perPage) => {
    setLoading(true);
    ReportService.getProducts({
      date_from,
      date_to,
      by_time,
      chart,
      page,
      perPage,
      search,
    })
      .then((res) => {
        setReportProduct(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch(disableRefetch(activeMenu));
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchReport();
    fetchProduct();
  }, [activeMenu.refetch, date_to, by_time, chart, search]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onChangePagination = (pagination) => {
    const { pageSize: perPage, current: page } = pagination;
    fetchProduct(page, perPage);
  };
  const excelExport = () => {
    setDownloading(true);
    ReportService.getProducts({ date_from, date_to, by_time, export: 'excel' })
      .then((res) => {
        const body = res.data.link;
        window.location.href = body;
      })
      .finally(() => setDownloading(false));
  };
  return (
    <Spin size='large' spinning={loading}>
      <Row gutter={24} className='mb-3'>
        <Col span={12}>
          <Space size='large'>
            <RangePicker
              defaultValue={[moment(date_from), moment(date_to)]}
              onChange={handleDateRange}
            />
          </Space>
        </Col>
      </Row>
      <Row gutter={24} className='report-products'>
        {chart_type?.map((item) => (
          <Col
            span={8}
            key={item.label}
            onClick={() => handleChart(item.value)}
          >
            <Card className={chart === item.value && 'active'}>
              <Row className='mb-5'>
                <Col>
                  <Text>{item.label}</Text>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Title level={2}>{reportData[item.qty]}</Title>
                </Col>
                <Col span={12} className='d-flex justify-content-end'>
                  <Tag color='geekblue' className='d-flex align-items-center'>
                    5%
                  </Tag>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
      <ReportChart reportData={reportData} chart_data='quantities_sum' />
      <Card>
        <Row
          gutter={24}
          className='align-items-center justify-content-between mb-4'
        >
          <Col span={4}>
            <Space>
              <Title level={2} className='mb-0'>
                Products
              </Title>
              <Tag color='geekblue'>Compare</Tag>
            </Space>
          </Col>
          <Col span={16}>
            <SearchInput
              style={{ width: '100%' }}
              handleChange={(e) => setSearch(e)}
            />
          </Col>
          <Col span={4} className='d-flex justify-content-end'>
            <Space>
              <Button
                icon={<CloudDownloadOutlined />}
                loading={downloading}
                onClick={excelExport}
              >
                Download
              </Button>
              <FilterColumns columns={columns} setColumns={setColumns} />
            </Space>
          </Col>
        </Row>
        <Table
          rowSelection={rowSelection}
          columns={columns?.filter((item) => item.is_show)}
          dataSource={reportProducts.data || []}
          rowKey={(row) => row.id}
          loading={loading}
          pagination={{
            pageSize: reportProducts?.per_page,
            page: reportProducts?.current_page || 1,
            total: reportProducts?.total,
            defaultCurrent: 1,
          }}
          onChange={onChangePagination}
        />
      </Card>
    </Spin>
  );
};

export default ReportProducts;
